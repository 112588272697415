<template>
  <div class="login">
    <div class="cover-container">
      <div class="cover py-5 bg-brand">
        <img src="@/assets/images/logos/logo.png" alt="" class="" />
      </div>
    </div>

    <div class="px-4">
      <div class="text-sm font-weight-bold h5 mb-4 mt-0 text-center">
        <span class="font-weight-bold h5 my-4 px-3 mt-0">تسجيل الدخول</span>
      </div>
      <div class="text-center p-5">
        قم بتسجيل الدخول أو اﻹشتراك للإستمتاع بالخدمة
      </div>
      <div class="text-center py-5 d-flex d-md-block">
        <button
          type="button"
          class="btn btn-success mx-1 col"
          @click="onSubscribe"
          :disabled="subscriptionLoading"
        >
          <i class="la la-spinner la-spin me-2" v-if="subscriptionLoading"></i>
          اﻹشتراك
        </button>
        <router-link 
        v-if="operatorName != 'zain'"
        :to="{ name: 'Login' }" class="btn btn-primary mx-1 col"
          >تسجيل الدخول</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";
import gql from "graphql-tag";

export default {
  components: {},

  setup() {
    const toast = useToast();
    const router = useRouter();

    let subscriptionLoading = ref(false);

    let phone = ref("");

    return {
      toast,
      subscriptionLoading,
      phone,
      router,
    };
  },

  data: () => {
    return {
      landingPage: process.env.VUE_APP_SUDANI_LANDING_PAGE_URL,
      loginURL: process.env.VUE_APP_SUDANI_HE_SUBSCRIPTION_URL,
      serviceCode: process.env.VUE_APP_SUDANI_SERVICE_CODE,
      operatorName: process.env.VUE_APP_PORTAL_OPERATOR,
    };
  },

  methods: {
    onSubscribe() {
      if (this.operatorName === "sudani") {
        if (
          process.env.VUE_APP_SUDANI_USE_LANDING_PAGE_FOR_SUBSCRIPTION == "true"
        ) {
          window.location.href = process.env.VUE_APP_SUDANI_LANDING_PAGE_URL;
        } else {
          this.submitSudaniHE();
        }
      }

      if (this.operatorName === "zain") {
        window.location.href = process.env.VUE_APP_ZAIN_LANDING_PAGE_URL;
      }
    },

    submitSudaniHE() {
      this.subscriptionLoading = true;
      fetch(this.loginURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: `serviceCode=${this.serviceCode}`,
      })
        .then((response) => response.text())
        .then((response) => {
          console.log("Data", response);

          this.prepareToLoginUser(response);
        });
    },

    prepareToLoginUser(responseData) {
      let data = this.prepareDataFromResponse(responseData);

      console.log("Data", data);

      if (data["status"] || data["responseCode"] == "111") {
        this.phone = data["msisdn"];
        this.loginUser();
      } else {
        console.log("HE failed, redirecting to otp...");
        this.$router.push({ name: "RegisterViaOTP" });
      }
    },

    loginUser() {
      this.subscriptionLoading = true;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation ($input: LoginUserInput!) {
              loginUser(input: $input) {
                message
                code
                success
                payload
              }
            }
          `,
          // Parameters
          variables: {
            input: {
              phone: this.phone,
            },
          },
        })
        .then((data) => {
          // Result
          console.log(data.data.loginUser);
          if (data.data.loginUser.success) {
            if (data.data.loginUser.code === 201) {
              this.$store.commit("SET_TOKEN", data.data.loginUser.payload);
              this.toast.success(data.data.loginUser.message);
              this.$router.push({ name: "Home" });
            } else {
              this.isOTPSent = true;
              this.requestId = data.data.loginUser.payload;
              this.toast.success(data.data.loginUser.message);
              setTimeout(() => {
                this.$refs.ist.focus();
              }, 500);
            }
          } else {
            this.toast.error(data.data.loginUser.message);
          }
          this.subscriptionLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.subscriptionLoading = false;
        });
    },

    prepareDataFromResponse(responseData) {
      responseData = responseData.replace(
        "document.getElementById('myHeader').submit();",
        ""
      );

      let fromDev = document.createElement("dev");

      fromDev.innerHTML = responseData;

      let form = fromDev.querySelector("form");

      let data = Array.from(new FormData(form));

      let dataObj = {};

      data.forEach((input) => (dataObj[input[0]] = input[1]));

      // Expected data:
      // dataObj["responseCode"];
      // dataObj["msisdn"];
      // dataObj["status"];
      // dataObj["serviceCode"];
      // dataObj["transactionID"];

      return dataObj;
    },
  },
};
</script>
